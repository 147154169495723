import {apiUrl, apiAccessToken} from './config.json';

export const baseUrl = apiUrl;
export const apiToken = apiAccessToken;

export const tokenName = 'jwtToken';
export const tokenExpiringDate = 'jwtTs';
export const linkExpiringDate = 'linkExp';

export const supportMail = 'acxiom_online@acxiom.com';

export const monthParser = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mär',
    '04': 'Apr',
    '05': 'Mai',
    '06': 'Jun',
    '07': 'Jul',
    '08': 'Aug',
    '09': 'Sep',
    '10': 'Okt',
    '11': 'Nov',
    '12': 'Dez'
};

export const notificationOptions = {
    timeOut: 3000,
    showCloseButton: true,
    progressBar: true,
    position: 'top-right'
};

export const inquiryResultNames = [
    ['prefixTitle', 'Anrede: '],
    ['name', 'Name: '],
    ['street', 'Straße: '],
    ['zipCode', 'PLZ: '],
    ['city', 'Ort: '],
    ['countryCode', 'Land: '],
    ['moveDate', 'Umzugsdatum: '],
    ['deceasedDate', 'Sterbedatum: '],
    ['deceasedDate2', 'Zugangsdatum: '],
    ['dateOfBirth', 'Geburtsdatum: ']
];

export const inquiryPreviousNames = [
    ['prefixTitle', 'Anrede: '],
    ['name', 'Name: '],
    ['previousStreet', 'Straße: '],
    ['previousZipCode', 'PLZ: '],
    ['previousCity', 'Ort: ']
];

export const tableDetailsSearched = [
    ['Vorgangsbezeichnung','VorgangID: '],
    ['Titel','Titel: '],
    ['Vorname','Vorname: '],
    ['Name','Name: '],
    ['Namenszusatz','Namenszusatz: '],
    ['StrasseHno','Straße: '],
    ['Postfach','Postfach: '],
    ['Postleitzahl','PLZ: '],
    ['Ort','Ort: '],
    ['Ortsteil','Ortsteil: '],
    ['Land','Land: '],
    ['Telefon','Telefon: '],
    ['Geburtsdatum','Geburtsdatum: '],
    ['Geburtsname','Früherer Name: '],
    ['Folgerecherche','Folgerecherche: '],
    ['EingangsDatum','Eingangsdatum: ', (values) => values.EingangsDatum? values.EingangsDatum.toString().substring(6,8)+'.'+values.EingangsDatum.toString().substring(4,6)+'.'+values.EingangsDatum.toString().substring(0,4) : '']
];

export const tableDetailsResults = [
    ['AAdrTyp','Adresstyp:', (values,customerId) => values.AAdrTyp && values.AAdrTyp === '0'? 'Unbekannt': values.AAdrTyp === '1'? 'Privat': 'Firma'],
    ['ATitel','Titel: '],
    ['AAnrede','Anrede: '],
    ['AVorname','Vorname: '],
    ['AName','Name: '],
    ['ANameZus','Namenszusatz: '],
    ['AStrasse','Straße: '],
    ['AAdrZus','Adresszusatz: '],
    ['APlz','PLZ: '],
    ['AOrt','Ort: '],
    ['AOrtsteil','Ortsteil: '],
    ['ALand','Land: '],
    ['AGebDatum','Geburtsdatum: ', (values,customerId) => values.AGebDatum && values.AGebDatum.substring(0,2) !== '00'? values.AGebDatum.substring(0,2)+'.'+values.AGebDatum.substring(2,4)+'.'+values.AGebDatum.substring(4,8): ''],
    ['AGebName','Früherer Name: '],
    ['SterbDatum','Sterbedatum: ', (values,customerId) => values.SterbDatum && values.SterbDatum.substring(0,2) !== '00'? values.SterbDatum.substring(0,2)+'.'+values.SterbDatum.substring(2,4)+'.'+values.SterbDatum.substring(4,8): ''],
    ['Bemerkung1','Bemerkung: '],
    ['Bemerkung2','Bemerkung: '],
    ['Ergebnis','Ergebnis: ', (values,customerId) => values.Ergebnis && values.Ergebnis === 'P'? 'Positiv': values.Ergebnis === 'I'? 'Informationen': values.Ergebnis === 'U'? 'Nicht bearbeitet': values.Ergebnis === 'N'? 'Negativ': ''],
    ['ErmittCode','Code Ermittlung: ', (values,customerId) => values.Ergebnis && values.ErmittCode? resultTranslator[values.Ergebnis][values.ErmittCode]: ''],
    ['FAdresse','Code Alt-Adresse: ', (values,customerId) => values.FAdresse? adressTranslator[values.FAdresse]: ''],
    ['FName','Code Name: ', (values,customerId) => values.FName? nameTranslator[values.FName]:''],
    ['FAnschrift','Code Neu-Adresse: ', (values,customerId) => values.FAnschrift? newAdressTranslator[values.FAnschrift]: ''],
    ['FGebDatum','Code Geburtsdatum: ', (values,customerId) => values.FGebDatum? dobTranslator[values.FGebDatum]: ''],
    ['InfoKosten','Kosten: ', (values,customerId) => calculatePrice(values,customerId)]
];

export const beatifyCost = (cost, withMwSt) =>{
    const costString = cost.toString();
    let beautifulString = '00,00'
    if (!costString.includes('.')) {
        //is integer
        beautifulString = costString + ',00';
    } else if (costString.split('.')[1].length < 2)
        beautifulString = costString + '0';
    else
        beautifulString = costString;
    if (withMwSt)
        return beautifulString + ' € (ohne MwSt.)';
    return beautifulString + ' €';
}

/*
    Lists all prices and the respective dates
*/
export const prices = {
    'general': {
        'ErfolgloseSuche': 0.00,
        'TrefferAdressänderungsaufträge': 2.48,
        'TrefferNachsendeaufträge': 2.99,
        'TrefferVerstorbene': 2.70,
        'AdressrecherchenPrivatadressen': 10.90,
        'AdressrecherchenSonst': 13.00,
        'Mindestumsatz': 50.00
    },
    'volkswohlbund': {
        'ErfolgloseSuche': 0.00,
        'TrefferAdressänderungsaufträge': 2.48,
        'TrefferNachsendeaufträge': 2.99,
        'TrefferVerstorbene': 2.70,
        'AdressrecherchenPrivatadressen': 10.00,
        'AdressrecherchenSonst': 13.00,
        'Mindestumsatz': 50.00
    }
}

export const priceList = {
    'general': [
        ['28.02.2023','01.03.2023'],
        ['Erfolglose Suchanfrage', '0,00 €', beatifyCost(prices['general']['ErfolgloseSuche'])],
        ['Treffer Adressänderungsaufträge', '1,98 €', beatifyCost(prices['general']['TrefferAdressänderungsaufträge'])],
        ['Treffer Nachsendeaufträge', '2,49 €', beatifyCost(prices['general']['TrefferNachsendeaufträge'])],
        ['Treffer Verstorbene', '2,20 €', beatifyCost(prices['general']['TrefferVerstorbene'])],
        ['Adressrecherchen Privatadressen', '10,40 €', beatifyCost(prices['general']['AdressrecherchenPrivatadressen'])],
        ['Adressrecherchen Privatadressen - Folgerecherchen', '10,40 €', beatifyCost(prices['general']['AdressrecherchenPrivatadressen'])],
        ['Adressrecherchen Firmen und unbekannt', '12,50 €', beatifyCost(prices['general']['AdressrecherchenSonst'])],
        ['Adressrecherchen Firmen und unbekannt - Folgerecherchen', '12,50 €', beatifyCost(prices['general']['AdressrecherchenSonst'])],
        ['Mindestumsatz pro Monat', '50,00 €', beatifyCost(prices['general']['Mindestumsatz'])]
    ],
    'volkswohlbund': [
        ['28.02.2023','01.03.2023'],
        ['Erfolglose Suchanfrage', '0,00 €', beatifyCost(prices['volkswohlbund']['ErfolgloseSuche'])],
        ['Treffer Adressänderungsaufträge', '1,98 €', beatifyCost(prices['volkswohlbund']['TrefferAdressänderungsaufträge'])],
        ['Treffer Nachsendeaufträge', '2,49 €', beatifyCost(prices['volkswohlbund']['TrefferNachsendeaufträge'])],
        ['Treffer Verstorbene', '2,20 €', beatifyCost(prices['volkswohlbund']['TrefferVerstorbene'])],
        ['Adressrecherchen Privatadressen', '10,40 €', beatifyCost(prices['volkswohlbund']['AdressrecherchenPrivatadressen'])],
        ['Adressrecherchen Privatadressen - Folgerecherchen', '10,40 €', beatifyCost(prices['volkswohlbund']['AdressrecherchenPrivatadressen'])],
        ['Adressrecherchen Firmen und unbekannt', '12,50 €', beatifyCost(prices['volkswohlbund']['AdressrecherchenSonst'])],
        ['Adressrecherchen Firmen und unbekannt - Folgerecherchen', '12,50 €', beatifyCost(prices['volkswohlbund']['AdressrecherchenSonst'])],
        ['Mindestumsatz pro Monat', '50,00 €', beatifyCost(prices['volkswohlbund']['Mindestumsatz'])]
    ]
};

export const priceChangeTimestamp = 1677628800000; //01.03.2023

/*const getChangedPrice = (oldPrice, newPrice) => {
    if(Date.now() > priceChangeTimestamp)
            return newPrice;
        else
            return oldPrice;
};*/

export const gotDifferentPrice = (customerId) => {
    return String(customerId).match("(310073044461\\d+)") || String(customerId).match("(0898570917500001)");
};

export const getInquiriyPricingInfo = (customerId) => {
    if (gotDifferentPrice(customerId))
        return [
            ['Mindestumsatz in Monaten mit Aktivitäten:',()=>{
                return beatifyCost(prices['general']['Mindestumsatz']);
            }],
            ['Preis bei erfolgloser Suchanfrage in der Umzugsdatenbank:',()=>{
                return beatifyCost(prices['general']['ErfolgloseSuche']);
            }],
            ['Preis bei Abruf der neuen Umzugs-Adresse (Nachsendeaufträge):',()=>{
                return beatifyCost(prices['general']['TrefferNachsendeaufträge']);
            }],
            ['Preis bei Abruf der neuen Umzugs-Adresse (Adressänderungsinformationen):',()=>{
                return beatifyCost(prices['general']['TrefferAdressänderungsaufträge']);
            }],
            ["Preis bei erfolgloser Suchanfrage in der Verstorbenen-Datenbank:",()=>{
                return beatifyCost(prices['general']['ErfolgloseSuche']);
            }],
            ["Preis bei einem Treffer in der Verstorbenen-Datenbank:",()=>{
                return beatifyCost(prices['general']['TrefferVerstorbene']);
            }],
            ['Alle Preise zzgl. gesetzl. MwSt.',()=>'']
        ];
    return [
        ['Mindestumsatz in Monaten mit Aktivitäten:',()=>{
            return beatifyCost(prices['volkswohlbund']['Mindestumsatz']);
        }],
        ['Preis bei erfolgloser Suchanfrage in der Umzugsdatenbank:',()=>{
            return beatifyCost(prices['volkswohlbund']['ErfolgloseSuche']);
        }],
        ['Preis bei Abruf der neuen Umzugs-Adresse (Nachsendeaufträge):',()=>{
            return beatifyCost(prices['volkswohlbund']['TrefferNachsendeaufträge']);
        }],
        ['Preis bei Abruf der neuen Umzugs-Adresse (Adressänderungsinformationen):',()=>{
            return beatifyCost(prices['volkswohlbund']['TrefferAdressänderungsaufträge']);
        }],
        ["Preis bei erfolgloser Suchanfrage in der Verstorbenen-Datenbank:",()=>{
            return beatifyCost(prices['volkswohlbund']['ErfolgloseSuche']);
        }],
        ["Preis bei einem Treffer in der Verstorbenen-Datenbank:",()=>{
            return beatifyCost(prices['volkswohlbund']['TrefferVerstorbene']);
        }],
        ['Alle Preise zzgl. gesetzl. MwSt.',()=>'']
    ];
};

export const getResearchPricingInfo = (customerId) => {
    if (gotDifferentPrice(customerId))
        return [
            ['Mindestumsatz in Monaten mit Aktivitäten:',{'private':()=>{
                return beatifyCost(prices['volkswohlbund']['Mindestumsatz']);
            },'buisines':()=>{
                return beatifyCost(prices['volkswohlbund']['Mindestumsatz']);
            },'unknown':()=>{
                return beatifyCost(prices['volkswohlbund']['Mindestumsatz']);
            }}],
            ['Preis pro durchgeführter Recherche (gleichgültig, ob erfolglos oder erfolgreich)',{'private':()=>{
                return beatifyCost(prices['volkswohlbund']['AdressrecherchenPrivatadressen']);
            },'buisines':()=>{
                return beatifyCost(prices['volkswohlbund']['AdressrecherchenSonst']);
            },'unknown':()=>{
                return beatifyCost(prices['volkswohlbund']['AdressrecherchenSonst']);
            }}],
            ['je Folgerecherche (falls sich die neu ermittelte Adresse bei der Endprüfung bereits wieder als unzustellbar erweist)',{'private':()=>{
                return beatifyCost(prices['volkswohlbund']['AdressrecherchenPrivatadressen']);
            },'buisines':()=>{
                return beatifyCost(prices['volkswohlbund']['AdressrecherchenSonst']);
            },'unknown':()=>{
                return beatifyCost(prices['volkswohlbund']['AdressrecherchenSonst']);
            }}],
        ];
    return [
        ['Mindestumsatz in Monaten mit Aktivitäten:',{'private':()=>{
            return beatifyCost(prices['general']['Mindestumsatz']);
        },'buisines':()=>{
            return beatifyCost(prices['general']['Mindestumsatz']);
        },'unknown':()=>{
            return beatifyCost(prices['general']['Mindestumsatz']);
        }}],
        ['Preis pro durchgeführter Recherche (gleichgültig, ob erfolglos oder erfolgreich)',{'private':()=>{
            return beatifyCost(prices['general']['AdressrecherchenPrivatadressen']);
        },'buisines':()=>{
            return beatifyCost(prices['general']['AdressrecherchenSonst']);
        },'unknown':()=>{
            return beatifyCost(prices['general']['AdressrecherchenSonst']);
        }}],
        ['je Folgerecherche (falls sich die neu ermittelte Adresse bei der Endprüfung bereits wieder als unzustellbar erweist)',{'private':()=>{
            return beatifyCost(prices['general']['AdressrecherchenPrivatadressen']);
        },'buisines':()=>{
            return beatifyCost(prices['general']['AdressrecherchenSonst']);
        },'unknown':()=>{
            return beatifyCost(prices['general']['AdressrecherchenSonst']);
        }}],
    ];
};

const getInfoCost = (date,customerId,AbfrageArt) =>{
    if (AbfrageArt === '1'){
        if (gotDifferentPrice(customerId)) {
            if(parseInt(date.substring(0,4)) >= 2023 && parseInt(date.substring(4,6)) >= 3)
                return 10.0;
            else
                return 9.5;
        }
        if(parseInt(date.substring(0,4)) >= 2023 && parseInt(date.substring(4,6)) >= 3)
            return 10.9;
        else if(parseInt(date.substring(0,4)) >= 2022 && parseInt(date.substring(4,6)) >= 3)
            return 10.4;
        else
            return 9.9;
    }
    if (gotDifferentPrice(customerId)) {
        if(parseInt(date.substring(0,4)) >= 2023 && parseInt(date.substring(4,6)) >= 3)
            return 13.00;
        else
            return 12.50;
    }
    if(parseInt(date.substring(0,4)) >= 2023 && parseInt(date.substring(4,6)) >= 3)
        return 13.00;
    else if(parseInt(date.substring(0,4)) >= 2022 && parseInt(date.substring(4,6)) >= 3)
        return 12.50;
    else
        return 12.50;
}

const calculatePrice = (data,customerId) => {
    if (!(data.Abfrageart && data.InfoEh))
        return '';
    const returnValue = getInfoCost(data.createdAt,customerId,data.AbfrageArt) * parseInt(data.InfoEh);
    return beatifyCost(returnValue);
};

const adressTranslator = {
    '1': 'Die Eingabeanschrift wurde in der Schreibweise normiert.',
    '2': 'Die Eingabeanschrift wurde postalisch korrigiert.',
    '3': 'Die Eingabeanschrift konnte nicht eindeutig identifiziert werden (mehrdeutig).',
    '4': 'Die Eingabeanschrift konnte nicht geprüft werden.',
    '5': 'Die Eingabeanschrift war bereits postalisch korrekt.'
};

const nameTranslator = {
    '': 'Keine Änderung.',
    '1': 'Der Vorname hat sich bei Ermittlung geändert.',
    '2': 'Der Name hat sich bei Ermittlung geändert.',
    '3': 'Vorname und Name haben sich bei Ermittlung geändert.'
};

const newAdressTranslator = {
    '': 'Keine Änderung.',
    '1': 'Straße oder Hausnummer hat sich geändert.',
    '2': 'PLZ oder Ort hat sich geändert.',
    '3': 'Straße und Ort haben sich geändert.'
};

const dobTranslator = {
    '': 'Keine Änderung.',
    '1': 'Das Geburtsdatum hat sich bei Ermittlung geändert.',
    '2': 'Das Geburtsdatum wurde hinzugefügt.',
    '3': 'Das Geburtsdatum wurde entfernt'
}

const resultTranslator = {
    'P': { //Positive
        '0': 'Anschrift wurde postalisch geprüft/korrigiert/normiert.',
        '1': 'Sehr sicherer Treffer in POSTADRESS CLEAN oder ergänzenden Verstorbeneninformationen.',
        '2': 'Sicherer Treffer in POSTADRESS CLEAN oder ergänzenden Verstorbeneninformationen.',
        '5': 'Sehr sicherer Treffer in POSTADRESS MOVE oder eränzenden Umzugsinformationen.',
        '6': 'Sicherer Treffer in POSTADRESS MOVE oder eränzenden Umzugsinformationen.',
        '9': 'Sicherer Treffer für einen Umzug ins Ausland in POSTADRESS MOVE oder ergänzenden Umzugsinformationen.',
        '': 'Aktuelle neue Anschrift in Deutschland (ohne Zustellprüfung).',
        'P': 'Aktuelle neue Anschrift in Deutschland mit positiver Zustellprüfung.',
        'Z': 'Aktuelle neue Anschrift in Deutschland mit negativer Zustellprüfung.',
        'B': 'Ergebnisanschrift identisch mit Anfrageanschrift (ohne Zustellprüfung).',
        'E': 'Ergebnisanschrift identisch mit Anfrageanschrift mit positiver Zustellprüfung.',
        'A': 'Ergebnisanschrift identisch mit Anfrageanschrift mit negativer Zustellprüfung.',
        'T': 'Aktuelle Anschrift in Deutschland (Wohnumfeld-Recherche)',
        'N': 'Aktuelle Anschrift in Deutschland, jedoch mit Namensänderung (Wohnumfeld-Recherche).',
        'V': 'Verstorben.',
        'W': 'Soll verstorben sein.',
        'X': 'Anschrift im Ausland (ohne Zustellprüfung).'
    },
    'I': { //Informations
        'B': 'Von Amtswegen abgemeldet, unbekannt verzogen.',
        'D': 'Unbekannt ins Ausland.',
        'E': 'Ohne festen Wohnsitz oder Meldeverhältnis nicht eindeutig.',
        'F': 'Firma erkannt.',
        'G': 'Amtliche Auskunftssperre.',
        'H': 'Ohne Angabe des Geburtsdatums nicht zu ermitteln.',
        'I': 'Keine 100%-tige Auskunft, ob Person richtig (Name, Geburtsdatum).',
        'K': 'Kreisumzug.',
        'S': 'Sammelunterkunft (Wohnumfeld-Recherche).',
        'U': 'Sammelunterkunft (automatisiert).'
    },
    'N': { //Negative
        'C': 'Unbekannt, nicht gemeldet.',
        'J': 'Letzte Anschrift ohne Hinweis auf gesuchte Person (Wohnumfeld-Recherche).',
        'O': 'Aufenthaltsort nicht ermittelbar (Wohnumfeld-Recherche).',
        'Q': 'Anschrift nicht recherchierbar (Wohnumfeld-Recherche).',
        'U': 'Ungültig.',
        'N': 'Kein Treffer bei Datenbankabgleichen.'
    },
    'U': { //Unprocessed
        '0': 'Recherchebudget unplausibel.',
        '1': 'Namensbestandteile nicht eindeutig bzw. fehlerhaft.',
        '2': '2 Personen angefragt. (z.B. Eheleute)',
        '3': 'Produktgruppe nicht eindeutig.',
        '4': 'Nicht unterstütztes Land angefragt.',
        '5': 'Anschrift fehlerhaft.',
        '6': 'Geburtsdatum fehlerhaft.',
        '7': 'Firma angefragt.',
        '8': 'Angaben unvollständig/nicht ausreichend.',
        '9': 'Vorgang bereits in Bearbeitung (doppeltes Aktenzeichen vorhanden).'
    },
    'X': { //Aborted
        'F': 'Abbruch der Recherche aufgrund Begrenzung/Ausschöpfung der fakurierbaren Leistungen (Zustellprüfung negativ).',
        'K': 'Kostenbedingter Abbruch der Recherche.',
        'M': 'Manueller Abbruch der Recherche durch Kundenzuruf.',
        'S': 'Systembedingter Abbruch der Recherche.'
    }
};

export const responseTranslator = {
    'default': {'text':'Ein unbekannter Fehler ist aufgetreten. Bitte versuchen Sie es erneut.'},
    'Unauthorized': {'text':'Die Sitzung ist abgelaufen oder Sie sind nicht berechtigt, diese Seite aufzurufen. Bitte loggen Sie sich ein.'},
    'checkAZ': {'text':'Vorgangsbezeichnung bereits vergeben. Bitte wählen Sie eine andere.'},
    '001': {'text':'Zugangsdaten fehlerhaft.'},
    '002': {'text':'Das Passwort ist abgelaufen. Bitte wählen Sie ein neues Passwort.'},
    '003': {'text': 'Alte Zugangsdaten fehlerhaft.'},
    '004': {'text': 'Neues Passwort genügt nicht den Anforderungen.'},
    '005': {'text': 'Bitte verwenden Sie kein altes Passwort.'}
};

export const pageStyle = `
    @media print {
        html, body {
            height: initial !important;
            overflow: initial !important;
            -webkit-print-color-adjust: exact;
            font-size: 1.4rem
        }
        table {
            width: 100%;
            margin-bottom: 1rem;
            color: #3e4676; }
            table th,
            table td {
              padding: 0.15rem;
              vertical-align: top;
              border-top: 1px solid #dee2e6; }
            table thead th {
              vertical-align: bottom;
              border-bottom: 2px solid #dee2e6; }
            table tbody + tbody {
              border-top: 2px solid #dee2e6; }
        @page{
        margin-left: 0px;
        margin-right: 0px;
        margin-top: 0px;
        margin-bottom: 0px;
        }
        `;