import React from "react";
import { Container, Row, Col } from "reactstrap";

const Footer = () => (
  <footer className="footer">
    <Container fluid>
      <Row className="text-muted">
        <Col xs={12} style={{display: 'flex', justifyContent: 'center'}}>
          <Row>
            &copy; {new Date().getFullYear()} -{" "}
            <a href="https://www.acxiom.de" target="_blank" rel="noopener noreferrer" className="text-muted ml-2">
              Acxiom
            </a>
            <a href="https://www.acxiom.de/agb/" target="_blank" rel="noopener noreferrer" className="text-muted ml-2">
              AGB
            </a>
            <a href="https://www.acxiom.de/datenschutz/" target="_blank" rel="noopener noreferrer" className="text-muted ml-2">
              Datenschutz
            </a>
            <a href="https://www.acxiom.de/impressum/" target="_blank" rel="noopener noreferrer" className="text-muted ml-2">
              Impressum
            </a>
          </Row>
        </Col>
      </Row>
    </Container>
  </footer>
);

export default Footer;
