import {
    LOGOUT_USER,
    LOGIN_USER_PENDING,
    LOGIN_USER,
    TOGGLE_SIDEBAR,
    INQUIRY_FAILED,
    CLEAR_ERROR_MESSAGE,
    CLEAR_RESPONSE_MESSAGE,
    SET_ERROR_MESSAGE,
    SET_RESPONSE_MESSAGE,
    SET_INFO_MESSAGE,
    CLEAR_INFO_MESSAGE,
    RESEARCH,
    RESEARCH_ERROR,
    RESEARCH_SUCCESS,
    GET_RESEARCH_RESULTS,
    GET_RESEARCH_RESULTS_ERROR,
    GET_OVERVIEWS,
    GET_OVERVIEWS_SUCCESS,
    GET_OVERVIEWS_ERROR,
    TOGGLE_PERMISSIONS,
    DELETE_ENTRY_SUCCESS,
    TOGGLE_QUESTIONS,
    AGREE,
    AGREE_SUCCESSFUL,
    GET_NOTIFICATION,
    GET_NOTIFICATION_ERROR,
    SET_LOGIN_STATUS
} from '../action';

const INIT_STATE = {
    isSidebarOpen: true,
    sidebar:{
        isOpen: true,
        isOnRight: false
    },
    customerId: '',
    errorMessage: '',
    responseMessage: '',
    infoMessage: '',
    permissions: null,
    reports: null,
    loggedIn: false,
    questionOpen: false,
    notificationData: {},
    notificationQueryCounter: 0,
    loginStatus: 1
};

const common = (state=INIT_STATE, action) => {
    switch(action.type) {
        case SET_LOGIN_STATUS:
            return {...state, loginStatus: action.payload};
        case TOGGLE_QUESTIONS:
            return {...state, questionOpen: !state.questionOpen}
        case SET_RESPONSE_MESSAGE:
            return {...state, responseMessage: action.payload};
        case SET_ERROR_MESSAGE:
            return {...state, errorMessage: action.payload};
        case SET_INFO_MESSAGE:
            return {...state, infoMessage: action.payload};
        case CLEAR_RESPONSE_MESSAGE:
            return {...state, responseMessage: INIT_STATE.responseMessage};
        case CLEAR_ERROR_MESSAGE:
            return {...state, errorMessage: INIT_STATE.errorMessage};
        case CLEAR_INFO_MESSAGE:
            return {...state, infoMessage: INIT_STATE.infoMessage};
        case LOGOUT_USER:
            return {...state, responseMessage: action.payload};
        case LOGIN_USER_PENDING:
            return {...state, loggedIn: 'pending'}
        case LOGIN_USER:
            return {...state, permissions: action.payload.permissions, customerId: action.payload.customer, loggedIn: 'loggedIn', reports: INIT_STATE.reports};
        case TOGGLE_SIDEBAR:
            return {...state, isSidebarOpen: !state.isSidebarOpen};
        case RESEARCH:
            return {...state, infoMessage: action.payload};
        case RESEARCH_SUCCESS:
            return {...state, responseMessage: action.payload};
        case RESEARCH_ERROR:
            return {...state, errorMessage: action.payload};
        case INQUIRY_FAILED:
            return {...state, errorMessage: action.payload};
        case GET_RESEARCH_RESULTS:
            return {...state};
        case GET_RESEARCH_RESULTS_ERROR:
            return {...state, errorMessage: action.payload};
        case GET_OVERVIEWS:
            return {...state};
        case GET_OVERVIEWS_SUCCESS:
            return {...state, reports: action.payload, responseMessage: 'Nachweise und Übersichten geladen.'};
        case GET_OVERVIEWS_ERROR:
            return {...state, errorMessage: action.payload};
        case DELETE_ENTRY_SUCCESS:
            return {...state, responseMessage: 'Eintrag gelöscht!'}
        case AGREE:
            return {...state};
        case AGREE_SUCCESSFUL:
            return {...state};
        case TOGGLE_PERMISSIONS:
            if (state.permissions === '11')
                return {...state, permissions: '10'};
            else
                return {...state, permissions: '11'};
        case GET_NOTIFICATION:
            const c1 = state.notificationQueryCounter +1;
            return {...state, notificationData: action.payload, notificationQueryCounter: c1};
        case GET_NOTIFICATION_ERROR:
            const c2 = state.notificationQueryCounter +1;
            return {...state, notificationData: INIT_STATE.notificationData, notificationQueryCounter: c2};
        default:
            return {...state};
    }
}

export default common;