import {
    INQUIRY,
    INQUIRY_SUCCESS,
    SAVE_SEARCH_FIELDS,
    CLEAR_SEARCH_FIELDS,
    CLEAR_INQUIRY,
    GET_RESEARCH_RESULTS_SUCCESS,
    GET_RESEARCH_RESULTS,
    CLEAR_RESEARCH_RESULTS
} from '../action';

const INIT_STATE = {
    inquriyResult: null,
    loading: false,
    searchFields: {
        id:'',
        surname:'',
        lastname:'',
        street: '',
        zip:'',
        city:'',
        dataSource:'adressInfo',
        searchType:'both'
    },
    /*searchFields: {
        id:'',
        surname:'isolde',
        lastname:'LICHNER',
        street: 'Weilimsdorferstrasse 203',
        zip:'70469',
        city:'Stuggi',
        dataSource:'adressInfo',
        searchType:'both'
    },*/
    /*searchFields: {
        id:'',
        surname:'oleksandr',
        lastname:'saloid',
        street: 'Adalbert Stifter Strase 1234',
        zip:'98765',
        city:'Kelkheim',
        dataSource:'adressInfo',
        searchType:'both'
    },*/
    /*searchFields: {
        id:'',
        surname:'nikolaus',
        lastname:'lins',
        street: 'Sinntalstrasse 2',
        zip:'36391',
        city:'Oberzell',
        dataSource:'adressInfo',
        searchType:'both'
    },*/
    /*searchFields: {
        id:'',
        surname:'Willhelm',
        lastname:'Wohlleben',
        street: 'Goethestrasse 14',
        zip:'89231',
        city:'Neu ulm',
        dataSource:'adressInfo',
        searchType:'both'
    },*/
    researchResults: null
};

const requests = (state = INIT_STATE, action) => {
    switch(action.type){
        case INQUIRY:
            return {...state, loading: true};
        case INQUIRY_SUCCESS:
            return {...state, loading: false, inquriyResult: action.payload};
        case SAVE_SEARCH_FIELDS:
            return {...state, searchFields: action.payload};
        case CLEAR_INQUIRY:
            return {...state, inquriyResult: INIT_STATE.inquriyResult};
        case CLEAR_SEARCH_FIELDS:
            return {...state, searchFields: INIT_STATE.searchFields};
        case GET_RESEARCH_RESULTS:
            return {...state, researchResults: INIT_STATE.researchResults};
        case GET_RESEARCH_RESULTS_SUCCESS:
            return {...state, researchResults: action.payload};
        case CLEAR_RESEARCH_RESULTS:
            return {...state, researchResults: INIT_STATE.researchResults};
        default:
            return {...state};
    }
};

export default requests;