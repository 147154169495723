// // // // //
//TO DEL
// // // // //
export const TOGGLE_PERMISSIONS = 'TOGGLE_PERMISSIONS';
export const TOGGLE_QUESTIONS = 'TOGGLE_QUESTIONS';

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_RESPONSE_MESSAGE = 'SET_RESPONSE_MESSAGE';
export const SET_INFO_MESSAGE = 'SET_INFO_MESSAGE';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';
export const CLEAR_RESPONSE_MESSAGE = 'CLEAR_RESPONSE_MESSAGE';
export const CLEAR_INFO_MESSAGE = 'CLEAR_INFO_MESSAGE';
export const AGREE = 'AGREE';
export const AGREE_SUCCESSFUL = 'AGREE_SUCCESSFUL';
export const GET_NOTIFICATION = "GET_NOTIFICATION";
export const GET_NOTIFICATION_ERROR = 'GET_NOTIFICATION_ERROR';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_PENDING = 'LOGIN_USER_PENDING';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const GET_OVERVIEWS = 'GET_OVERVIEWS';
export const GET_OVERVIEWS_SUCCESS = 'GET_OVERVIEWS_SUCCESS';
export const GET_OVERVIEWS_ERROR = 'GET_OVERVIEWS_ERROR';
export const REQUEST_OVERVIEW = 'REQUEST_OVERVIEW';

export const GET_CONTRACT_1 = 'GET_CONTRACT_1';
export const GET_CONTRACT_2 = 'GET_CONTRACT_2';

export const INQUIRY = 'INQUIRY';
export const INQUIRY_SUCCESS = 'INQUIRY_SUCCESS';
export const INQUIRY_FAILED = 'INQUIRY_FAILED';
export const CLEAR_INQUIRY = 'CLEAR_INQUIRY';
export const SAVE_SEARCH_FIELDS = 'SAVE_SEARCH_FIELDS';
export const CLEAR_SEARCH_FIELDS = 'CLEAR_SEARCH_FIELDS';
export const RESEARCH = 'RESEARCH';
export const RESEARCH_SUCCESS = 'RESEARCH_SUCCESS';
export const RESEARCH_ERROR = 'RESEARCH_ERROR';
export const GET_RESEARCH_RESULTS = 'GET_RESEARCH_RESULTS';
export const GET_RESEARCH_RESULTS_SUCCESS = 'GET_RESEARCH_RESULTS_SUCCESS';
export const GET_RESEARCH_RESULTS_ERROR = 'GET_RESEARCH_RESULTS_ERROR';
export const DELETE_ENTRY = 'DELETE_ENTRY';
export const DELETE_ENTRY_SUCCESS = 'DELETE_ENTRY_SUCCESS';
export const CLEAR_RESEARCH_RESULTS = 'CLEAR_RESEARCH_RESULTS';

export * from './common/actions';
export * from './requests/actions';