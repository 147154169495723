import {createStore, applyMiddleware} from 'redux';
import rootReducer from './reducer';
import thunk from 'redux-thunk';

const store = createStore(
    rootReducer,
    undefined,
    applyMiddleware(thunk)
    //For Debugging via Browser Plugin
    /*compose(
        applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
    )*/
);

export default store;