import React, {Component} from 'react';
import { Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import {connect} from 'react-redux';
import {toggleQuestion} from '../../redux/action';

class QuestionsModal extends Component {

    render(){
        const {questionOpen, toggleQuestion} = this.props;
        return (
            <Modal
            size='sm'
            isOpen={questionOpen}
            toggle={()=>toggleQuestion()}>
                <ModalHeader toggle={()=>toggleQuestion()}>Sie haben Fragen?</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            Sie können uns gerne via Email<br/><a href='mailto:info-germany@acxiom.com'>info-germany@acxiom.com</a> kontaktieren.
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = ({common}) => {
    const {questionOpen} = common;
    return {questionOpen};
}
  
export default connect(
    mapStateToProps,
    {toggleQuestion}
)(QuestionsModal);