import {
    tokenName,
    tokenExpiringDate,
    responseTranslator,
    linkExpiringDate
} from './defaultValues';

export const isLoggedIn = () => {
    const now = Date.now();
    if (getToken() && now < getTokenExpiringDate())
        return true;
    return false;
};

export const isLinkExpired = () => {
    const now = Date.now();
    if(now < getLinkExpiringDate())
        return false;
    return true;
};

export const getToken = () => {
    return localStorage.getItem(tokenName);
};

export const getTokenExpiringDate = () => {
    return localStorage.getItem(tokenExpiringDate);
};

export const getLinkExpiringDate = () => {
    return localStorage.getItem(linkExpiringDate)? localStorage.getItem(linkExpiringDate): Date.now()-20;
};

export const extractMessageFromError = (error) => {
    return error.response ? error.response.data.message : error.message;
};

export const parseErrorMessages = (error,context) => {
    if(!error.response)
        return responseTranslator['default']['text'];
    const errorCode = error.response.data['message'].split('_')[0];
    if(Object.keys(responseTranslator).includes(errorCode) && Object.keys(responseTranslator[errorCode]).includes('call')) {
        setTimeout(() => responseTranslator[errorCode]['call'](),1500);
        return responseTranslator[errorCode]['text'];
    } else if (Object.keys(responseTranslator).includes(errorCode))
        return responseTranslator[errorCode]['text'];
    return responseTranslator['default']['text'];
};

export const extractResultHeader = (result) => {
    if(result.isHit === 'supHitOnly' || result.isHit === 'supAndMvHit')
        return 'Diese Person ist leider verstorben.';
    else if (result.isHit === 'mvHitOnly')
        return 'Diese Person ist umgezogen.';
    else if (result.isHit === '')
        return 'Wir haben leider keine Informationen zu dieser Person.';
    else
        return 'Unbekanntes Ergebnis.';
};

export const parseSearchDate = (date) => {
    const s = date.split('-');
    let out = parseInt(s[0])*10000000000000;
    out += parseInt(s[1])*100000000000;
    out += parseInt(s[2])*1000000000;
    return out;
};

// dateString = YYYYMMDD
export const parseDateString = (dateString) => {
    return `${dateString.substring(6)}.${dateString.substring(4,6)}.${dateString.substring(0,4)}`
};

export function beautyDate(num){
    const input = parseInt(num);
    if (input < 10)
      return `0${input.toString()}`;
    return `${input.toString()}`;
  }