import React, {Component} from "react";
import { connect } from "react-redux";
import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import QuestionsModal from "../components/modals/QuestionsModal";


class Dashboard extends Component{

  render() {
    const {sidebar, children} = this.props;
    return (
      <React.Fragment>
        <Wrapper>
          {sidebar && !sidebar.isOnRight && <Sidebar />}
          <Main>
            <Navbar />
            <Content>{children}</Content>
            <QuestionsModal/>
            <Footer />
          </Main>
          {sidebar && sidebar.isOnRight && <Sidebar />}
        </Wrapper>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({common}) => {
  const {sidebar,user} = common;
  return {sidebar,user};
}

export default connect(
  mapStateToProps,
  {}
)(Dashboard);